import React, { useContext, useEffect } from "react";
import UserContext from "../Utils/User_Context";
import ApiNames from "../Constants/ApiNames";
import { fetchGet } from "../Utils/FetchApi";
import { encode as base64_encode } from "base-64";
import tagManagerEvents from "../Utils/GoogleTagManager";
import Awareness from "../Components/Awareness";
import TagManager from "react-gtm-module";

const AwarenessScreen = () => {
  const userContext = useContext(UserContext);

  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const utm_source = searchParams.get("utm_source");
  const qsPackageId = searchParams.get("package_id")
    ? searchParams.get("package_id")
    : "1";

  useEffect(() => {
    if (userContext.token) {
      authFlow();
    }
  }, [userContext.token]);
  useEffect(() => {
    setTimeout(() => {
      gtmInitialization();
    }, 1000);
  }, []);
  const gtmInitialization = () => {
    const tagManagerArgs = {
      gtmId: "GTM-WJWD9KV",
    };
    TagManager.initialize(tagManagerArgs);
  };

  const authFlow = async () => {
    try {
      let _qsPackageId = "1";
      if (qsPackageId === "1" || qsPackageId === "2" || qsPackageId === "3") {
        _qsPackageId = qsPackageId;
      }
      let endPoint = `${ApiNames.GetAwareness}?utm_source=${utm_source}&package_id=${_qsPackageId}`;
      let response = await fetchGet(endPoint, userContext.token);
      if (response.status === 0) {
        if (_qsPackageId === "1") {
          tagManagerEvents("signup", "econTikTok");
        } else if (_qsPackageId === "2") {
          tagManagerEvents("signup", "econTikTokWeekly");
        } else if (_qsPackageId === "3") {
          tagManagerEvents("signup", "econTikTokMonthly");
        }

        let url = window.location.origin;
        let encodedMsisdn = base64_encode(response.msisdn);
        let encodedToken = base64_encode(response.token);
        url = `${url}/home?user=${encodedMsisdn}&auth=${encodedToken}`;
        if (url.includes("http:")) {
          url = url.replace("http", "https");
        }
        window.location.replace(url);
      } else {
        navigateToLanding();
      }
    } catch (err) {
      navigateToLanding();
    }
  };
  const navigateToLanding = () => {
    let url = window.location.origin;
    url = `${url}/landing`;
    window.location.replace(url);
  };

  return <Awareness />;
};

export default AwarenessScreen;
